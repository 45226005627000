<template>
	<div class="product">
		<div class="square_button remove" @click="removeProduct()"></div>
		<div class="input_container price">
			<input
				type="number"
				v-model="productInfo.price"
				placeholder="Цена"
				@input="updateProduct"
				@change="updateProduct"
				@click="pasteFromClipboard('price')"
			/>
		</div>
		<div class="input_container link">
			<input
				type="text"
				placeholder="Ссылка"
				v-model="productInfo.link"
				@input="updateProduct"
				@change="updateProduct"
				@click="pasteFromClipboard('link')"
			/>
		</div>
		<div class="input_container delivery">
			<input
				type="text"
				v-model="productInfo.deliveryPrice"
				placeholder="Доставка"
				@input="updateProduct"
				@change="updateProduct"
			/>
		</div>
		<div class="rating">
			<div class="star-rating">
				<label
					class="star-rating__star"
					:key="rating"
					v-for="rating in ratings"
					:class="{
						'is-selected':
							productInfo.rating >= rating && productInfo.rating != null
					}"
					@click="setRating(rating)"
					@mouseover="starOver(rating)"
					@mouseout="starOut"
					>★</label
				>
			</div>
		</div>
		<label class="box switcher">
			<input
				class="toggle"
				type="checkbox"
				true-value="true"
				false-value="false"
				@change="updateProduct"
				v-model="productInfo.isBox"
			/>
			<span>Боксы</span>
		</label>
	</div>
</template>

<script>
export default {
	props: {
		apiProduct: {
			type: Object,
			required: true
		},
		productDataTemplate: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		}
	},

	data() {
		return {
			productInfo: { ...this.productDataTemplate },
			temp_value: null,
			ratings: [1, 2, 3, 4, 5]
		};
	},

	methods: {
		starOver(index) {
			this.temp_value = this.productInfo.rating;
			this.productInfo.rating = index;
		},

		starOut() {
			this.productInfo.rating = this.temp_value;
		},

		setRating(value) {
			this.temp_value = value;
			this.productInfo.rating = value;
			this.updateProduct();
		},

		updateProduct() {
			this.$emit("updateProduct", {
				productInfo: this.productInfo,
				index: this.index
			});
		},

		removeProduct() {
			this.$emit("removeProduct", this.index);
		},

		async pasteFromClipboard(name) {
			const text = await navigator.clipboard.readText();
			if (text && !this.productInfo[name]) {
				this.productInfo[name] = text;
			}
		}
	},

	created() {
		for (let key in this.apiProduct) {
			this.productInfo[key] = this.apiProduct[key];
		}
	}
};
</script>

<style lang="scss" scoped>
.product {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.438em 0;
	border-bottom: 2px solid #eeeeee;
}
.remove {
	background-color: #f1f1f1;
	&:hover {
		background-color: #df0024;
		&:after,
		&:before {
			background-color: white;
		}
	}
	&:after,
	&:before {
		background-color: black;
	}
}
.price {
	width: 11%;
}
.link {
	width: 32%;
}
.delivery {
	width: 11%;
}
.rating {
	width: 16.5%;

	.star-rating {
		margin: 0 -3px;
		&__star {
			display: inline-block;
			padding: 0 3px;
			line-height: 1;
			font-size: 2.4em;
			color: #ababab;
			transition: color 0.2s ease-out;

			&:hover {
				cursor: pointer;
			}

			&.is-selected {
				color: #ffd700;
			}

			&.is-disabled:hover {
				cursor: default;
			}
		}
	}
}
.switcher {
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 1.188em;
		font-weight: 500;
	}
	.toggle {
		position: relative;
		width: 2.9em;
		height: 24px;
		margin-right: 0.6em;
		border-radius: 0.75em;
		background-color: #aaacaf;
		-webkit-appearance: none;
		appearance: none;
		-webkit-tap-highlight-color: transparent;
		overflow: hidden;
		cursor: pointer;
		transition: transform 0.3s, background 300ms ease-in-out;
		&:before,
		&:after {
			content: "";
			position: absolute;
			display: block;
			border-radius: 50%;
			top: 2px;
			left: 0px;
			width: 1.25em;
			height: 1.25em;
			background: white;
			transition: transform 0.3s;
		}
		&:after {
			transition-delay: calc(0.3s * 0.75);
			transition-timing-function: cubic-bezier(0.3, 1.6, 0.5, 0.7);
			transform: translateX(2px);
		}
		&:before {
			transform: translateX(-1.5em);
			transition-delay: 0s;
		}
		&:checked {
			background-color: #65bc3d;
			&:before {
				transition-delay: calc(0.3s * 0.75);
				transition-timing-function: cubic-bezier(0.3, 1.6, 0.5, 0.7);
				transform: translateX(1.5em);
			}
			&:after {
				transition-delay: 0s;
				transform: translateX(3em);
			}
		}
	}
}
</style>
