import axios from "axios";

export const getSearchResults = async () => {
	try {
		const response = await axios.post(
			"https://scanme-admin.sigma-studio.pp.ua/api/getSearchResults.php"
		);
		return response.data;
	} catch (errors) {
		console.error(errors);
	}
};
