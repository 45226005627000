import axios from "axios";

export const getParamsByBarcode = async (data) => {
	try {
		const response = await axios.post(
			"https://nomera.sigma-studio.pp.ua/barcode/get_names.php",
			data
		);
		return response.data;
	} catch (errors) {
		console.error(errors);
	}
};
