import { createRouter, createWebHistory } from "vue-router";
import SearchResults from "@/views/SearchResults";
const routes = [
	{
		path: "/",
		name: "Main",
		component: SearchResults
	}
];
export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes
});
