<template>
	<header class="header">
		<span class="title"> Админ-панель </span>
	</header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header {
	background-color: #f8f8f8;
	border-bottom: 1px solid #dbdbdb;
	padding: 0.8em 1.7em;
}
.title {
	color: #2c2e2f;
	font-size: 1.213em;
}
</style>
