<template>
	<transition name="show">
		<div class="snack_bar" :class="status" v-if="show">
			<span>{{ text }}</span>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			text: "",
			time: 2000,
			show: false,
			status: ""
		};
	},

	methods: {
		showSnackBar(data) {
			this.show = true;
			this.text = data.text;
			if (data.time) {
				this.time = data.time;
			}
			this.status = data.status;
			setTimeout(() => {
				this.show = false;
			}, this.time);
		}
	}
};
</script>

<style lang="scss" scoped>
.snack_bar {
	position: fixed;
	top: 1em;
	left: 50%;
	background-color: rgba(0, 0, 0, 0.85);
	padding: 0.4em 1.5em;
	border-radius: 40px;
	transform: translate(-50%, 0%);
	z-index: 9999999;
	transition: all 400ms ease-in-out;
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
		transform: translate(-50%, -150%);
	}
	&.success {
		background-color: #65bc3d;
	}
	&.error {
		background-color: #df0024;
	}
	span {
		color: white;
		font-size: 0.875em;
		font-weight: 500;
		white-space: nowrap;
	}
}
</style>
