<template>
	<div class="search_item">
		<div class="search_info">
			<div class="image" @click="changeImage()">
				<img :src="searchItem.image" alt="" v-if="searchItem.image" />
				<img src="~@/assets/images/svg/no_image.svg" alt="" v-else />
			</div>
			<div class="controls">
				<div class="title" :class="{ open: isOpen }" @click="isOpen = !isOpen">
					<span>{{ data.search_params[0] }}</span>
					<div class="plus"></div>
				</div>
				<div class="values">
					<div class="input_container barcode">
						<input type="number" :value="data.barcode" readonly />
					</div>
					<div class="input_container name">
						<input
							type="text"
							v-model="searchItem.name"
							@change="updateSearchQuery()"
							@click="pasteFromClipboard('name')"
						/>
					</div>
					<div class="buttons">
						<a :href="createGoogleLink" class="requests" target="_blank"
							>Запросы</a
						>
						<div class="square_button remove" @click="removeSearchItem()"></div>
						<div class="square_button add" @click="addProduct()"></div>
					</div>
				</div>
			</div>
		</div>
		<slide-up-down
			v-model="isOpen"
			:responsive="true"
			:duration="500"
			class="search_products"
		>
			<search-product
				:key="product.link + index"
				v-for="(product, index) in searchItem.add_products"
				:apiProduct="product"
				:productDataTemplate="productDataTemplate"
				:index="index"
				@updateProduct="updateProduct($event)"
				@removeProduct="removeProduct($event)"
			/>
		</slide-up-down>
	</div>
</template>

<script>
import SearchProduct from "@/components/SearchProduct.vue";
import SlideUpDown from "vue3-slide-up-down";
export default {
	props: {
		data: {
			type: Object,
			required: true
		},
		productDataTemplate: {
			type: Object,
			required: true
		},
		index: {
			type: Number,
			required: true
		}
	},

	components: {
		SearchProduct,
		SlideUpDown
	},

	data() {
		return {
			searchItem: {},
			isOpen: typeof this.data.isOpen !== "undefined" ? this.data.isOpen : false
		};
	},

	computed: {
		createGoogleLink() {
			// let searchQuery = this.data.search_params[0]
			// 	.split(",")
			// 	.map(function (item) {
			// 		return item.trim();
			// 	});
			return (
				"https://www.google.com/search?client=opera&q=" +
				this.data.search_params[0] +
				"&sourceid=opera&ie=UTF-8&oe=UTF-8"
			);
		}
	},

	methods: {
		removeSearchItem() {
			this.$emit("removeSearchItem", this.index);
		},

		updateSearchQuery() {
			this.$emit("updateSearchQuery", {
				data: this.data,
				index: this.index
			});
		},

		addProduct() {
			this.searchItem.add_products.push(this.productDataTemplate);
		},

		updateProduct(data) {
			this.searchItem.add_products[data.index] = data.productInfo;
			this.updateSearchQuery();
		},

		removeProduct(index) {
			this.searchItem.add_products.splice(index, 1);
			this.updateSearchQuery();
		},

		async changeImage() {
			const image = await navigator.clipboard.readText();
			this.searchItem.image = image;
			this.updateSearchQuery();
		},

		async pasteFromClipboard(name) {
			const text = await navigator.clipboard.readText();
			if (text && !this.searchItem[name]) {
				this.searchItem[name] = text;
			}
		}
	},

	created() {
		this.searchItem = this.data;
	},

	mounted() {
		setTimeout(() => {
			if (this.index === 0) {
				this.isOpen = true;
			}
		}, 400);
	}
};
</script>

<style lang="scss" scoped>
.search_item {
	padding: 2em 1.813em;
	margin-bottom: 2em;
	background-color: white;
	border-radius: 12px;
}
.search_info {
	display: flex;
	justify-content: space-between;
}
.image {
	width: 8%;
}
.controls {
	width: 90.4%;
	.title {
		position: relative;
		display: block;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 0.875em;
		cursor: pointer;
		&.open {
			.plus {
				&:before {
					transform: translate(-50%, -50%) rotate(90deg);
				}
			}
		}
	}
	.plus {
		position: absolute;
		width: 1.125em;
		height: 1.125em;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		&:after,
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: black;
			transition: $defaultTransition;
		}
		&:after {
			width: 100%;
			height: 2px;
		}
		&:before {
			width: 2px;
			height: 100%;
		}
	}
	.values {
		display: flex;
		justify-content: space-between;
		padding-top: 2em;
		border-top: 2px solid #eeeeee;
	}
	.barcode {
		width: 19.7%;
	}
	.name {
		width: 44%;
	}
	.buttons {
		display: flex;
		justify-content: space-between;
		width: 31.3%;
		.requests {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 61.5%;
			background-color: #1d1d1d;
			color: white;
			transition: $defaultTransition;
			&:hover {
				background-color: #333333;
			}
		}
		.remove {
			background-color: #df0024;
			&:hover {
				background-color: #ff1238;
			}
		}
		.add {
			background-color: #65bc3d;
			&:hover {
				background-color: #73d944;
			}
		}
	}
}
.search_products {
	padding-top: 1.913em;
	.product {
		&:last-child {
			border: none;
		}
		&:first-child {
			border-top: 2px solid #eeeeee;
		}
	}
}
</style>
