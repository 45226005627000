<template>
	<div class="page_content">
		<div class="search_list">
			<search-item
				:key="search"
				v-for="(search, index) in searchResults"
				:data="search"
				:productDataTemplate="productDataTemplate"
				:index="index"
				@updateSearchQuery="updateSearchQuery($event)"
				@removeSearchItem="removeSearchItem($event)"
			/>
		</div>
		<div class="button add_search_item" @click="addSearchItem()"></div>
		<snack-bar ref="snackBar" />
	</div>
</template>

<script>
import { getSearchResults } from "@/api/getSearchResults.js";
import { getParamsByBarcode } from "@/api/getParamsByBarcode.js";
import { saveSearchResults } from "@/api/saveSearchResults.js";
import { removeSearchResult } from "@/api/removeSearchResult.js";
import SearchItem from "@/components/SearchItem.vue";
import SnackBar from "@/components/SnackBar.vue";
export default {
	components: { SearchItem, SnackBar },

	data() {
		return {
			searchResults: [],
			productDataTemplate: {
				price: "",
				link: "",
				deliveryPrice: "",
				rating: 4,
				isBox: false
			}
		};
	},

	methods: {
		async addSearchItem() {
			const barcode = await navigator.clipboard.readText();
			if (barcode) {
				let params = await this.getParamsByBarcode(barcode);
				this.createSearchItem(params, barcode);
			} else {
				this.$refs.snackBar.showSnackBar({
					text: "Буфер пустой",
					status: "error"
				});
			}
		},

		createSearchItem(params, barcode) {
			this.searchResults.unshift({
				add_products: [this.productDataTemplate],
				barcode: barcode,
				id: 2,
				name: "",
				image: "",
				request_numbers: 1,
				search_params: params,
				isOpen: true
			});
		},

		async removeSearchItem(index) {
			let formData = new FormData();
			formData.append("barcode", this.searchResults[index].barcode);
			let response = await removeSearchResult(formData);
			if (response === "success") {
				this.$refs.snackBar.showSnackBar({
					text: "Успешно",
					status: "success"
				});
			}
			if (response === "failure") {
				this.$refs.snackBar.showSnackBar({ text: "Ошибка", status: "error" });
			}
			this.searchResults.splice(index, 1);
		},

		async getParamsByBarcode(barcode) {
			let formData = new FormData();
			formData.append("barcode", barcode);
			let response = await getParamsByBarcode(formData);
			return response;
		},

		updateSearchQuery(data) {
			this.searchResults[data.index] = data.data;
		},

		async saveSearchQueries() {
			let formData = new FormData();
			formData.append("queries", JSON.stringify(this.searchResults));
			const response = await saveSearchResults(formData);
			if (response === "success") {
				this.$refs.snackBar.showSnackBar({
					text: "Успешно",
					status: "success"
				});
			}
			if (response === "failure") {
				this.$refs.snackBar.showSnackBar({ text: "Ошибка", status: "error" });
			}
		},

		checkButtonPressed() {
			if (event.keyCode === 13) {
				this.saveSearchQueries();
			}
		}
	},

	async created() {
		this.searchResults = await getSearchResults();
	},

	mounted() {
		document.addEventListener("keydown", this.checkButtonPressed);
	},

	beforeUnmount() {
		document.removeEventListener("keydown", this.checkButtonPressed);
	}
};
</script>

<style lang="scss" scoped>
.page_content {
	padding: 2.313em 2.813em;
	min-height: 100vh;
	background-color: #eeeeee;
}
.add_search_item {
	position: fixed;
	width: 3.25em;
	height: 3.25em;
	right: 2.813em;
	bottom: 3em;
	border-radius: 50%;
	background-color: #65bc3d;
	cursor: pointer;
	transition: $defaultTransition;
	z-index: 99999;
	&:hover {
		background-color: #73d944;
	}
	&:after,
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: white;
	}
	&:before {
		width: 35%;
		height: 2px;
	}
	&:after {
		width: 2px;
		height: 35%;
	}
}
</style>
