import axios from "axios";

export const saveSearchResults = async (data) => {
	try {
		const response = await axios.post(
			"https://scanme-admin.sigma-studio.pp.ua/api/saveSearchResults.php",
			data
		);
		if (response.data === "success") {
			return "success";
		} else {
			return "failure";
		}
	} catch {
		return "failure";
	}
};
